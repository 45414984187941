import { StudentDetail, StudentList, } from '@agent-ds/shared/interfaces';
import { ApiService } from '@agent-ds/shared/models/api-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CustomerApiService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    getList(search) {
        return this.http.get('/api/customer/search', { params: search });
    }
    getDetail(id) {
        return this.http.get('/api/customers/' + id).pipe(tap((student) => {
            student.userId = student.studentUsers && student.studentUsers.length ? student.studentUsers[0].userId : null;
            // 副担当者のIDを抽出
            if (student.studentSubUsers && student.studentSubUsers.length) {
                student.subUserIds = student.studentSubUsers.map((studentSubUser) => {
                    return studentSubUser.userId;
                });
            }
            else {
                // do nothing
            }
            return student;
        }));
    }
    /*
    後々拡張の可能性あり
    updateMemo(id: number, memo: SituationMemo): Observable<any> {
      return this.http.put(`/api/customers/${id}/memos/${memo.id}`, memo);
    }
  
    updateNote(studentId: number, note: StudentNote): Observable<any> {
      return this.http.put(`/api/customers/${studentId}/note/${note.id}`, note);
    }
    */
    updateExpectedCompanyFlag(studentId, currentProgressId, updateProgressId) {
        return this.http.put(`/api/customer/expected/${studentId}/${currentProgressId}/${updateProgressId}`, {});
    }
}
CustomerApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerApiService_Factory() { return new CustomerApiService(i0.ɵɵinject(i1.HttpClient)); }, token: CustomerApiService, providedIn: "root" });
